import CreateActivity from './create';
import EditActivity from './edit';
import ActivityList from './list';
import ActivityShow from './show';

export default {
    list: ActivityList,
    create: CreateActivity,
    edit: EditActivity,
    show: ActivityShow,
};
