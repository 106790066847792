import Administrator from './Administrator';
import University from './University';

export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    OTHER = 'OTHER',
}

export enum UserStatus {
    ACTIVE = 'ACTIVE',
    BANNED = 'BANNED',
    REPORTED = 'REPORTED',
    CANCELED = 'CANCELED',
}

export enum UserRole {
    STUDENT = 'STUDENT',
    STAFF = 'STAFF',
}

type User = {
    id: string;
    status: UserStatus;
    role: UserRole;
    email: string;
    firstname: string;
    lastname: string;
    gender: Gender;
    university: University;
    age: number;
    contact: Administrator;
    avatar: {
        id: string;
        mimeType: string;
    };
};

export default User;
