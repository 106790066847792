import ActivityCategoriesCreate from './create';
import ActivityCategoriesEdit from './edit';
import ActivityCategoriesList from './list';
import ActivityCategoriesShow from './show';

export default {
    list: ActivityCategoriesList,
    show: ActivityCategoriesShow,
    edit: ActivityCategoriesEdit,
    create: ActivityCategoriesCreate,
};
